<template>
  <div class="manage">
    <div class="nav">
      <!-- <div>奖项申报平台</div> -->
      <div></div>
      <div>
        <div>用户名：{{ user_nam }}</div>
        <!-- <div>
          身份：
          <span v-if="user_details_split_id > 0">申报者</span>
          <span v-else>管理员</span>
        </div> -->
        <div @click="dialogFormVisible = true">修改密码</div>
        <div @click="tuichu">退出</div>
      </div>
    </div>
    <div class="main">
      <!-- <div class="main_l" v-if="menuShow">
        <el-menu
          :default-active="num"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-submenu
            :index="item.id + ''"
            v-for="(item, index) in menus"
            :key="index"
          >
            <template slot="title">
              <i :class="item.ioc"></i>
              <span>{{ item.menu }}</span>
            </template>
            <el-menu-item
              :index="item1.id + ''"
              v-for="(item1, index) in item.data"
              :key="index"
              @click="push(item1)"
              >{{ item1.menu }}</el-menu-item
            >
          </el-submenu>
        </el-menu>
      </div> -->
      <div class="main_r">
        <router-view />
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="30%">
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        :label-width="formLabelWidth"
      >
        <el-form-item label="用户名" prop="user_name">
          <el-input v-model="form.user_name" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="旧密码" :label-width="formLabelWidth">
          <el-input
            v-model="form.old_password"
            autocomplete="off"
            placeholder="请输入旧密码"
            style="width: 350px"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="phones"
          label="新密码"
          :label-width="formLabelWidth"
        >
          <el-input
            maxlength="20"
            type="password"
            v-model="form.password"
            placeholder="请输入密码"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item
          prop="phones"
          label="新密码"
          :label-width="formLabelWidth"
        >
          <el-input
            maxlength="20"
            type="password"
            v-model="form.password1"
            placeholder="请再次输入密码"
            @blur="Verification"
            style="width: 350px"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="queding">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { menu, log_out, user_forget } from "../axios/api";
export default {
  data() {
    return {
      menuShow: true,
      user_nam: sessionStorage.getItem("user_name") || "",
      user_details_split_id:
        sessionStorage.getItem("user_details_split_id") || "",
      formLabelWidth: "80px",
      dialogFormVisible: false,
      num: "",
      menus: [],
      form: {
        password: "",
        old_password: "",
        user_name: "",
        password1: "",
        token: sessionStorage.getItem("Tokens"),
      },
      com_name: sessionStorage.getItem("com_name") || "",
      rules: {
        user_name: [
          { required: true, message: "请填写用户名", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    if (sessionStorage.getItem("group_id") == 2) {
      this.menuShow = false;
    }
    this.menu();
  },

  components: {},

  methods: {
    queding() {
      this.form.token = sessionStorage.getItem("Tokens");
      let params = this.form;
      console.log(params);
      user_forget(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.dialogFormVisible = false;
          this.tuichu();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    Verification() {
      if (this.form.password1.length >= 6) {
        if (this.form.password1 != this.form.password) {
          this.$message({
            message: "两次密码不一致请重新输入",
            type: "error",
          });
          this.form.password1 = "";
        }
      } else {
        this.form.password = "";
        this.form.password1 = "";
        this.$message({
          message: "密码最低为6位数",
          type: "error",
        });
      }
    },
    tuichu() {
      let params = {
        token: sessionStorage.getItem("Tokens"),
      };
      log_out(params).then((res) => {
        if (res.data.code == 200) {
          sessionStorage.removeItem("Tokens");
          this.$router.push({ path: "/login" });
        }
      });
    },
    push(e) {
      console.log(e);
      sessionStorage.setItem("id", e.id);
      this.$router.push({ path: e.menu_url });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    menu() {
      let params = {
        token: sessionStorage.getItem("Tokens"),
      };
      menu(params).then((res) => {
        // console.log(res);
        this.menus = res.data.data;
        this.num = this.menus[0].data["0"].id + "";
      });
    },
  },
};
</script>

<style scoped>
.manage {
  width: 100%;
  height: 100%;
}
.nav {
  width: 100%;
  height: 150px;
  background: url("../assets/static/img/houBan.jpg") no-repeat 100%;
  background-size: cover;
  box-sizing: border-box;
  padding: 0 70px;
  position: relative;
}
.nav > div:nth-child(2) {
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;

  right: 20px;
  bottom: 20px;
}

.nav > div:nth-child(2) > div {
  margin-right: 30px;
  font-size: 600;
}
.main {
  width: 100%;
  height: calc(100% - 150px);
  background-color: #fff;
  display: flex;
}
.main_r {
  /* width: calc(100% - 15%); */
  flex: 1;
  height: calc(100vh - 150px);
  background-color: #eee;
  box-sizing: border-box;
  padding: 10px;
}
.main_r > div {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}
.main_l {
  width: 15%;
  height: 100%;
}
</style>